<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">รายละเอียดหัตถการ/คอร์ส</h4>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="4">
          <span><v-icon class="mr-3">mdi-auto-fix</v-icon>{{operativecourse_name}}</span>
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_status}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ราคาขาย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(operativecourse_costprice)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือแพทย์:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(operativecourse_wagesdoctor)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือพนักงาน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(operativecourse_wagesemployee)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนครั้ง:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_amountcourse || 'ไม่จำกัดจำนวนครั้ง'}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนวัน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{operativecourse_amountday || '-'}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-column align-start justify-start">
              <span class="label-drudsandmedical-add-stock-incard">รายละเอียดอื่นๆ:</span>
              <template v-for="(item, index) in dataDrugs">
                <span :key="index" class="text-start">{{`${index+1}. ${item.drugsupply_name} ${item.drugsupply_amount} ${item.drugsupply_unit}`}}</span>
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="8">
          <div class="mb-3 d-flex flex-row align-center justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="mr-3 white--text"
              @click="addDrug"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มยา
            </v-btn>
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="mr-3 white--text"
              @click="addSupply"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มอุปกรณ์
            </v-btn>
          </div>
          <span>รายการยาและอุปกรณ์</span>
          <v-data-table
            :headers="headers"
            :items="dataDrugs"
            :loading="loaddata"
            loading-text="กำลังโหลด..."
          >
            <template v-slot:item.drugsupply_name="{ item }">
              <div class="text-start">{{item.drugsupply_name}}</div>
            </template>
            <template v-slot:item.drugsupply_costperunit="{ item }">
              <div :class="formatThaiBaht(item.drugsupply_costperunit) ? 'text-end' : ''">{{formatThaiBaht(item.drugsupply_costperunit) || '-'}}</div>
            </template>
            <template v-slot:item.drugsupply_costprice="{ item }">
              <div :class="formatThaiBaht(item.drugsupply_costprice) ? 'text-end' : ''">{{formatThaiBaht(item.drugsupply_costprice) || '-'}}</div>
            </template>
            <template v-slot:item.drugsupply_status="{ item }">
              <div class="d-flex justify-center align-center">
                <v-switch
                  v-model="item.drugsupply_status_bool"
                  readonly
                  inset
                  dense
                ></v-switch>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                @click="deleteItem(item)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initData"
              >
                รีเซ็ต
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <div class="d-flex flex-row align-center justify-end mt-5">
        <v-btn
          color="success"
          @click="createNewList()"
          :loading="loaderSave"
          :disabled="loaderSave"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          บันทึก
        </v-btn>
        <v-btn
          color="secondary"
          text
          class="ml-3"
          @click="goBack()"
          :loading="loaderCancel"
          :disabled="loaderCancel"
        >
          <v-icon class="mr-2">mdi-undo-variant</v-icon>
          ย้อนกลับ
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title><v-icon class="mr-3">mdi-plus-circle</v-icon>{{addDrugDialog ? 'เพิ่มยา' : 'เพิ่มอุปกรณ์'}}</v-card-title>
        <v-card-text>
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">{{`ชื่อ${addDrugDialog ? 'ยา' : 'อุปกรณ์'}*`}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-select
                :items="addDrugDialog ? itemsDrug : itemsSupply"
                v-model="dataDialog.drugsupply_name"
                item-text="name"
                item-value="name"
                :label="addDrugDialog ? 'ชื่อยา*' : 'ชื่ออุปกรณ์*'"
                :placeholder="addDrugDialog ? 'เลือกยา' : 'เลือกอุปกรณ์'"
                no-data-text="ยังไม่มีข้อมูล"
                :rules="selectRules"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนครั้ง/คอร์ส*</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.drugsupply_amountpercourse"
                :rules="inputRules"
                label="จำนวนครั้ง/คอร์ส*"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวน*</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.drugsupply_amount"
                :rules="inputRules"
                label="จำนวน*"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialog">
            <v-icon class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submitAddDialog"
          >
            <v-icon class="mr-3">mdi-content-save</v-icon>
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loaderSave: null,
      loaderCancel: null,
      createtypecourse: '',
      itemsCategory: [],
      operativecourse_category: '',
      operativecourse_code: '',
      operativecourse_name: '',
      operativecourse_costprice: 0,
      operativecourse_wagesdoctor: 0,
      operativecourse_wagesemployee: 0,
      operativecourse_amountcourse: 0,
      operativecourse_amountday: 0,
      operativecourse_detail: '',
      dataDrugs: [],
      loaddata: null,
      loader: null,
      inputRules: [
        v => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectRules: [
        v => !!v || 'กรุณาเลือกข้อมูล'
      ],
      addDialog: false,
      addDrugDialog: false,
      addSupplyDialog: false,
      itemsDrug: [],
      itemsSupply: [],
      dataDialog: {}
    }
  },
  created () {
    const self = this
    self.loaddata = true
    self.operativecourse_status = 'เปิดใช้งาน'
    self.operativecourse_category = self.$route.params.createItem.operativecourse_category
    self.operativecourse_code = self.$route.params.createItem.operativecourse_category
    self.operativecourse_name = self.$route.params.createItem.operativecourse_category
    self.operativecourse_costprice = self.$route.params.createItem.operativecourse_category
    self.operativecourse_wagesdoctor = self.$route.params.createItem.operativecourse_category
    self.operativecourse_wagesemployee = self.$route.params.createItem.operativecourse_category
    self.operativecourse_amountcourse = self.$route.params.createItem.operativecourse_category
    self.operativecourse_amountday = self.$route.params.createItem.operativecourse_category
    self.operativecourse_detail = self.$route.params.createItem.operativecourse_category

    self.dataDrugs = [{
      _id: '000',
      no: 1,
      drugsupply_category: 'ยา',
      drugsupply_name: 'ไหมก้างปลา 8D',
      drugsupply_amountpercourse: 1,
      drugsupply_amount: 4,
      drugsupply_unit: 'เส้น',
      drugsupply_costperunit: 750,
      drugsupply_costprice: 3000,
      drugsupply_status: 'เปิดใช้งาน',
      drugsupply_status_bool: true
    }, {
      _id: '001',
      no: 2,
      drugsupply_category: 'ยา',
      drugsupply_name: 'ไหมทอร์นาโด',
      drugsupply_amountpercourse: 1,
      drugsupply_amount: 2,
      drugsupply_unit: 'เส้น',
      drugsupply_costperunit: 1000,
      drugsupply_costprice: 2000,
      drugsupply_status: 'เปิดใช้งาน',
      drugsupply_status_bool: true
    }]

    self.itemsDrug = [{
      _id: '0000',
      name: 'ไหมก้างปลา 8D',
      value: '8D'
    }]
    self.itemsSupply = [{
      _id: '0000',
      name: 'เข็มทู่สำหรับร้อยไหมทอร์นาโด',
      value: 'tornado'
    }]
    setTimeout(() => {
      self.loaddata = false
    }, 200)
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'no'
        }, {
          text: 'หมวดหมู่',
          align: 'center',
          sortable: false,
          value: 'drugsupply_category'
        }, {
          text: 'รายการ',
          align: 'center',
          sortable: false,
          value: 'drugsupply_name'
        }, {
          text: 'จำนวนครั้ง (ครั้งต่อคอร์ส)',
          align: 'center',
          sortable: false,
          value: 'drugsupply_amountpercourse'
        }, {
          text: 'จำนวน',
          align: 'center',
          sortable: false,
          value: 'drugsupply_amount'
        }, {
          text: 'หน่วยนับ',
          align: 'center',
          sortable: false,
          value: 'drugsupply_unit'
        }, {
          text: 'ราคาต่อหน่วย',
          align: 'center',
          sortable: false,
          value: 'drugsupply_costperunit'
        }, {
          text: 'ราคารวม',
          align: 'center',
          sortable: false,
          value: 'drugsupply_costprice'
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'drugsupply_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    initData () {
      const self = this
      self.dataDrugs = [{
        _id: '000',
        no: 1,
        drugsupply_category: 'ยา',
        drugsupply_name: 'ไหมก้างปลา 8D',
        drugsupply_amountpercourse: 1,
        drugsupply_amount: 4,
        drugsupply_unit: 'เส้น',
        drugsupply_costperunit: 750,
        drugsupply_costprice: 3000,
        drugsupply_status: 'เปิดใช้งาน',
        drugsupply_status_bool: true
      }, {
        _id: '001',
        no: 2,
        drugsupply_category: 'ยา',
        drugsupply_name: 'ไหมทอร์นาโด',
        drugsupply_amountpercourse: 1,
        drugsupply_amount: 2,
        drugsupply_unit: 'เส้น',
        drugsupply_costperunit: 1000,
        drugsupply_costprice: 2000,
        drugsupply_status: 'เปิดใช้งาน',
        drugsupply_status_bool: true
      }]

      self.itemsDrug = [{
        _id: '0000',
        name: 'ไหมก้างปลา 8D',
        value: '8D'
      }]
      self.itemsSupply = [{
        _id: '0000',
        name: 'เข็มทู่สำหรับร้อยไหมทอร์นาโด',
        value: 'tornado'
      }]
    },
    goBack () {
      const self = this
      self.loaderSave = true
      self.loaderCancel = true

      setTimeout(() => {
        self.loaderSave = null
        self.loaderCancel = null
        self.$router.go(-1)
      }, 200)
    },
    formatThaiBaht (num) {
      if (num) {
        var p = num.toFixed(2).split('.')
        return p[0].split('').reverse().reduce((acc, num, i, orig) => {
          return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
        }, '') + '.' + p[1]
      } else return ''
    },
    addDrug () {
      const self = this
      self.addDialog = true
      self.addDrugDialog = true
      self.dataDialog.drugsupply_amountpercourse = 0
      self.dataDialog.drugsupply_amount = 0
    },
    addSupply () {
      const self = this
      self.addDialog = true
      self.addSupplyDialog = true
      self.dataDialog.drugsupply_amountpercourse = 0
      self.dataDialog.drugsupply_amount = 0
    },
    closeDialog () {
      const self = this
      self.addDialog = false
      self.addDrugDialog = false
      self.addSupplyDialog = false
      self.dataDialog = {}
    },
    submitAddDialog () {
      const self = this
      if (self.dataDialog.drugsupply_name && self.dataDialog.drugsupply_amountpercourse && self.dataDialog.drugsupply_amount) {
        swal('สำเร็จ', self.addDrugDialog ? 'เพิ่มรายการยาสำเร็จ' : 'เพิ่มรายการอุปกรณ์สำเร็จ', 'success', {
          button: false,
          timer: 3000
        }).then(() => self.closeDialog())
      } else {
        swal('คำเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน', 'warning', {
          button: false,
          timer: 3000
        })
      }
    },
    deleteItem (item) {
      const self = this
      var listindex = null
      listindex = self.dataDrugs.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการ ${item.drugsupply_name} ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.dataDrugs.splice(listindex, 1)
            // self.initData()
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
        }
      })
    },
    createNewList () {
      const self = this
      self.loaderSave = true
      self.loaderCancel = true

      setTimeout(() => {
        swal('สำเร็จ', 'เพิ่มรายการหัตถการ/คอร์สสำเร็จ', 'success', {
          button: false,
          timer: 3000
        }).then(() => {
          self.loaderSave = null
          self.loaderCancel = null
          self.$router.go(-2)
        })
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/DrugsAndMedical_AddStock.css';
</style>
